import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import Lenis from '@studio-freight/lenis';

class Main extends ViewBasic {
    constructor(options) {
        super(options);
        this.name = 'Main';
        this.mouse = null;
        this.customCursor = null; 

        this.initialize();
    }

    initialize() {
        this.bindAll([
            'resize',
            'pageTransitionned',
            'mouseMoveEvent',
            'showCustomCursorEvent',
            'hideCustomCursorEvent',
        ]);

        this.customCursor = document.querySelector('.custom-cursor');

        //this class is applying css transition duration of 0 to prevent animating from resetting when refreshing the page.
        document.querySelector('body').classList.remove('preload');

        this.breakpoints = [
            window.SETTINGS.BREAKPOINTS.SM_MIN,
            window.SETTINGS.BREAKPOINTS.MD_MIN,
            window.SETTINGS.BREAKPOINTS.LG_MIN,
        ];

        this.addEvents();

        this.pageTransitionned();

        // Init smoothscroll
        const lenis = new Lenis({
            wrapper: document.body,
            wheelMultiplier: 0.9,
        });

        lenis.on('scroll', ScrollTrigger.update);
        gsap.ticker.add((time)=>{
            lenis.raf(time * 1000)
        })

        gsap.ticker.lagSmoothing(0)

        window.lenis = lenis;

        //make sure to go to anchors
        if (location.hash.slice(1)) {
            const targetElement = document.querySelector(location.hash);
            window.lenis.scrollTo(targetElement, {
                offset: targetElement.classList.contains('scroll-anim-list-item') ? -100 : 0,
                duration: 1,
                easing: function easeInOutSine(x) {
                    return -(Math.cos(Math.PI * x) - 1) / 2;
                },
            });
        }
    }
 
    addEvents() {
        document.querySelectorAll('a').forEach((link) => {
            if (link.href.indexOf('#') != -1) {
                link.addEventListener('click', () => {
                    const targetElement = document.querySelector('#' + link.getAttribute('href').split('#')[1]);
                    window.lenis.scrollTo(targetElement, {
                        offset: targetElement.classList.contains('scroll-anim-list-item') ? -100 : 0,
                        duration: 1,
                        easing: function easeInOutSine(x) {
                            return -(Math.cos(Math.PI * x) - 1) / 2;
                        },
                    });
                });
            }
        });
        window.addEventListener('resize', this.resize);
        window.addEventListener('pageTransition', this.pageTransitionned);

        // Custom cursor
        gsap.set(this.customCursor, { xPercent: -50, yPercent: -50 });

        const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        this.mouse = { x: pos.x, y: pos.y };
        // You can tweak this value
        const speed = 0.15;

        const xSet = gsap.quickSetter(this.customCursor, 'x', 'px');
        const ySet = gsap.quickSetter(this.customCursor, 'y', 'px');

        window.addEventListener('mousemove', this.mouseMoveEvent);

        gsap.ticker.add(() => {
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

            pos.x += (this.mouse.x - pos.x) * dt;
            pos.y += (this.mouse.y - pos.y) * dt;

            xSet(pos.x);
            ySet(pos.y);
        });
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('pageTransition', this.pageTransitionned);

        window.removeEventListener('mousemove', this.mouseMoveEvent);
        const customCursorElements = document.querySelectorAll(
            '.hover-custom-cursor',
        );
        if (customCursorElements.length) {
            customCursorElements.forEach((customCursorElement) => {
                customCursorElement.removeEventListener(
                    'mouseenter',
                    this.showCustomCursorEvent,
                );
                customCursorElement.removeEventListener(
                    'mouseleave',
                    this.hideCustomCursorEvent,
                );
            });
        }
    }

    anchorLinkHandler() {}

    pageTransitionned() {
        // Init anchor links for smoothscroll
        const links = document.querySelectorAll('a');
        if (links.length) {
            links.forEach((link) => {
                if (
                    link.href.indexOf('#') != -1 &&
                    link.href.indexOf(window.location) != -1
                ) {
                    link.addEventListener('click', () => {
                        window.lenis.scrollTo('#' + link.getAttribute('href').split('#')[1], {
                            offset: 0,
                            duration: 1,
                        });
                    });
                }
            });
        }

        // Init parallax elements
        const parallaxBgs = gsap.utils.toArray('.parallax-bg');
        if (parallaxBgs.length != 0) {
            let multiplier = window.innerWidth < 1024 ? 20 : 100;
            parallaxBgs.forEach((parallaxBg) => {
                gsap.to(parallaxBg, {
                    scrollTrigger: {
                        trigger: parallaxBg,
                        scrub: true,
                    },
                    y: (i, target) =>
                        parseFloat(target.dataset.speed) * multiplier,
                    ease: 'none',
                });
            });
        }

        // Init fade-in animations
        const fadeIns = gsap.utils.toArray('.fade-in');
        if (fadeIns.length != 0) {
            fadeIns.forEach((fadeIn) => {
                gsap.fromTo(
                    fadeIn,
                    {
                        opacity: 0,
                        y: 30,
                    },
                    {
                        opacity: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: fadeIn,
                            start: 'top bottom',
                            end: 'top 60%',
                        },
                        ease: 'power3.inOut',
                        duration: 0.9,
                    },
                );
            });
        }

        // Init lists animations
        const scrollAnimListItems = gsap.utils.toArray(
            '.scroll-anim-list-item',
        );
        if (scrollAnimListItems.length != 0) {
            scrollAnimListItems.forEach((scrollAnimListItem) => {
                let scrollTriggerOptions = {
                    trigger: scrollAnimListItem,
                    start: 'top bottom',
                    end: 'top 60%',
                    scrub: 0.5,
                };

                gsap.fromTo(
                    scrollAnimListItem,
                    { y: 100 },
                    {
                        y: 0,
                        scrollTrigger: scrollTriggerOptions,
                        scrub: 0.5,
                    },
                );

                const separator = scrollAnimListItem.querySelector(
                    '.scroll-anim-item-separator',
                );
                if (separator) {
                    gsap.fromTo(
                        separator,
                        { y: 100 },
                        {
                            y: 0,
                            scrollTrigger: scrollTriggerOptions,
                        },
                    );
                }
            });
        }

        // Init featured articles animations
        const scrollAnimArticlesFeatured = gsap.utils.toArray(
            '.scroll-anim-article-featured',
        );
        if (scrollAnimArticlesFeatured.length != 0) {

            let verticalDisplacement = 0;

            scrollAnimArticlesFeatured.forEach((scrollAnimArticleFeatured, index) => {



                let scrollTriggerOptions = {
                    trigger: scrollAnimArticleFeatured,
                    start: 'top bottom',
                    end: 'top 60%',
                    scrub: 0.5,
                };

                gsap.fromTo(
                    scrollAnimArticleFeatured,
                    { y: verticalDisplacement },
                    {
                        y: 0,
                        scrollTrigger: scrollTriggerOptions,
                        scrub: 0.5,
                    },
                );

                scrollAnimArticlesFeatured.length === 3 ? index % 2 === 0 ? verticalDisplacement += 200 : verticalDisplacement -= 100 : verticalDisplacement += 100;
            });
        }

        // Add this class to elements where you want the custom cursor to appear
        const customCursorElements = document.querySelectorAll(
            '.hover-custom-cursor',
        );
        if (customCursorElements.length) {
            customCursorElements.forEach((customCursorElement) => {
                customCursorElement.addEventListener(
                    'mouseenter',
                    this.showCustomCursorEvent,
                );
                customCursorElement.addEventListener(
                    'mouseleave',
                    this.hideCustomCursorEvent,
                );
            });
        }
        // Call hide event in case the cursor stays between pages
        this.hideCustomCursorEvent();
    }

    resize(e) {
        let breakpoint;
        const viewPortSize = this.GET_OUTER_WIDTH(
            document.querySelector('html'),
        );

        if (viewPortSize <= window.SETTINGS.BREAKPOINTS.XS_MAX) {
            window.isMobile = true;
        }
        if (viewPortSize <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
            window.isTablet = true;
        } else {
            window.isTablet = false;
            window.isMobile = false;
        }

        if (viewPortSize < this.breakpoints[0]) {
            breakpoint = String(this.breakpoints[0] - 1) + '-';
        }

        for (let i in this.breakpoints) {
            if (
                this.breakpoints[Number(i) + 1] != undefined &&
                viewPortSize >= this.breakpoints[i] &&
                viewPortSize < this.breakpoints[Number(i) + 1]
            ) {
                breakpoint =
                    String(this.breakpoints[i]) +
                    '-' +
                    String(this.breakpoints[Number(i) + 1] - 1);
            }
            if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
                breakpoint = String(this.breakpoints[i]) + '+';
            }
        }

        if (window.SETTINGS.BREAKPOINT != breakpoint) {
            window.SETTINGS.BREAKPOINT = breakpoint;

            let eventDispatch = new CustomEvent('breakpointChanged', {
                bubbles: true,
                detail: {
                    breakpoint: breakpoint,
                },
            });

            //dispatch breakpointChanged
            window.dispatchEvent(eventDispatch);
        }

        window.dispatchEvent(new Event('window.resize'));
    }

    GET_OUTER_WIDTH(element) {
        let width = element.offsetWidth;
        let style = getComputedStyle(element);

        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    }

    showCustomCursorEvent(event) {
        if (event.target.classList.contains('hover-custom-cursor--small')) {
            this.customCursor.classList.add('custom-cursor--small');
        }
        else this.customCursor.classList.remove('custom-cursor--small');

        gsap.to(this.customCursor, {
            opacity: 1,
            duration: 0.5,
            ease: 'power3.out',
        });
    }

    hideCustomCursorEvent() {
        gsap.to(this.customCursor, {
            opacity: 0,
            duration: 0.5,
            ease: 'power3.out',
        });
    }

    mouseMoveEvent(event) {
        this.mouse.x = event.x;
        this.mouse.y = event.y;
    }
}

gsap.registerPlugin(ScrollTrigger, SplitText);

document.addEventListener('DOMContentLoaded', function () {
    new Main({});
});

Me.views['Main'] = Main;
